import React, { Component } from 'react';
import { shape } from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { graphql } from 'gatsby';
import formatGraphqlData from '../utils/gatsbyGraphql';
import Layout from '../components/layout';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';
import PdfIcon from '../images/pdficon.png';

class JournalArticlesPublicationsPage extends Component {
  static propTypes = {
    data: shape({}).isRequired,
  };

  constructor() {
    super();
    this.state = {
      displayData: null,
      pdfCategories: [],
      pdfCategoryDisplayNames: {},
    };
  }

  buildCategories = (pdfs) => {
    let { displayData } = this.state;
    const { pdfCategories, pdfCategoryDisplayNames } = this.state;
    if (displayData === null) {
      displayData = {};
      pdfs.map((pdf) => {
        const { pdfcategory, name, pdf: pdffile } = pdf;

        if (pdfcategory) {
          if (displayData[pdf.pdfcategory.slug]) {
            displayData[pdf.pdfcategory.slug].pdfs.push({ ...pdffile, name });
          } else {
            displayData[pdf.pdfcategory.slug] = {
              name: pdf.pdfcategory.name,
              pdfs: [],
            };
            displayData[pdf.pdfcategory.slug].pdfs.push({ ...pdffile, name });
          }
          if (pdfCategories.indexOf(pdf.pdfcategory.slug) === -1) {
            pdfCategories.push(pdf.pdfcategory.slug);
          }
          if (!(pdf.pdfcategory.slug in pdfCategoryDisplayNames)) {
            pdfCategoryDisplayNames[pdf.pdfcategory.slug] = pdf.pdfcategory.name;
          }
        }
        return pdf;
      });
      this.setState({ displayData, pdfCategories, pdfCategoryDisplayNames });
    }
  }

  render() {
    const { data } = this.props;
    const { displayData, pdfCategories, pdfCategoryDisplayNames } = this.state;
    const { allStrapiPdf } = data;
    const pdfs = formatGraphqlData(allStrapiPdf);

    this.buildCategories(pdfs);

    return (
      <Layout title="Journal Articles &amp; Publications">
        <Hero backgroundImage="zoo-pharm" headline="Journal Articles &amp; Publications" tagline="" />
        <section className="content-wrapper zoo-pharm-banner">
          <Grid justifyContent="center" alignItems="center">
            <GridItem md={8}>
              <h2>ZooPharm Journal Articles &amp; Publications</h2>
              <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={pdfCategories}>
                { pdfCategories && pdfCategoryDisplayNames && pdfCategories.map(pdfCategory => (
                  <AccordionItem key={pdfCategory} uuid={pdfCategory}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {pdfCategoryDisplayNames[pdfCategory]}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      { displayData && displayData[pdfCategory].pdfs.map(pdf => (
                        <a
                          className="pdflink"
                          href={`${process.env.GATSBY_BROWSER_API_BASE_URL}/pdfs${pdf.url}`}
                          key={pdf.slug}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img alt={pdf.pdffilename} className="pdficon" src={PdfIcon} />
                          {pdf.name}
                        </a>
                      ))}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </GridItem>
          </Grid>
        </section>
      </Layout>
    );
  }
}

export default JournalArticlesPublicationsPage;
export const query = graphql`
  query JournalArticlesPublicationsPage {
    allStrapiPdf {
      edges {
        node {
          id
          name
          slug
          pdf {
            name
            url
          }
          pdfcategory {
            id
            name
            slug
          }
        }
      }
    }
  }
`;
